import bg from "../img/bg40.jpeg";

const NotFound = () => {

  document.title = "404 | IT-Dienstleistungen Hauser";

  return (
    <>
      <div className="container px-0">
        <div className="cover-img-container" style={{background: `url(${bg})`}}>
          <h1 className="mb-0 cover-img-title">404</h1>
          <div className="w-100 cover-img" style={{ objectPosition: "center" }} />
        </div>
      </div>
      <div>
        <div className="d-flex justify-content-center content-card first imprint" style={{ marginLeft: 40 }}>
          <div className="content">
            <div className="d-block d-md-flex text-start">
              <div style={{ paddingTop: 30 }}>
                <h4>404 - Ups!</h4>
                <p>Die Seite konnte leider nicht gefunden werden</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NotFound;
