import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route, NavLink, Link, useLocation } from "react-router-dom";

import Home from "./pages/home";
import Leistungen from "./pages/profil";
import Contact from "./pages/contact";

import Impressum from "./pages/impressum";
import Datenschutz from "./pages/datenschutz";
import NotFound from "./pages/404";

import logo from "./img/logo4.png";

import bars from "./img/bars.svg";
import times from "./img/times.svg";
import i3u from "./img/i3u.png";

const useMatomoTracking = () => {
  const location = useLocation();

  useEffect(() => {
    const { _paq } = window;
    if (_paq) {

      const currentUrl = location.pathname + (location.search || "");
      _paq.push(["setCustomUrl", currentUrl]);
      _paq.push(["setDocumentTitle", currentUrl]);

      // remove all previously assigned custom variables, requires Matomo (formerly Piwik) 3.0.2
      _paq.push(["deleteCustomVariables", "page"]);
      _paq.push(["setPagePerformanceTiming", 0]);
      _paq.push(["trackPageView"]);

      // make Matomo aware of newly added content
      let content = document.getElementById("root");
      _paq.push(["MediaAnalytics::scanForMedia", content]);
      _paq.push(["FormAnalytics::scanForForms", content]);
      _paq.push(["trackContentImpressionsWithinNode", content]);
      _paq.push(["enableLinkTracking"]);
    }
  }, [location]);
};

const Footer = () => (
      <footer className="container py-2 highlight-border-r highlight-border-b footer">
        <div className="d-flex justify-content-between">
          <div className="imprint mx-4">
            <a href="https://github.com/hauserandr" title="Besuchen Sie meinen GitHub-Account. Mithilfe von Github können wir gemeinsam Ihre IT-Dienstleistungen verwalten.">
              <svg
                className="octicon octicon-mark-github v-align-middle"
                height="32"
                viewBox="0 0 16 16"
                version="1.1"
                width="32"
                aria-hidden="true"
                style={{marginTop: "8px"}}
              >
                <path
                  fill="#000"
                  d="M8 0C3.58 0 0 3.58 0 8c0 3.54 2.29 6.53 5.47 7.59.4.07.55-.17.55-.38 0-.19-.01-.82-.01-1.49-2.01.37-2.53-.49-2.69-.94-.09-.23-.48-.94-.82-1.13-.28-.15-.68-.52-.01-.53.63-.01 1.08.58 1.23.82.72 1.21 1.87.87 2.33.66.07-.52.28-.87.51-1.07-1.78-.2-3.64-.89-3.64-3.95 0-.87.31-1.59.82-2.15-.08-.2-.36-1.02.08-2.12 0 0 .67-.21 2.2.82.64-.18 1.32-.27 2-.27.68 0 1.36.09 2 .27 1.53-1.04 2.2-.82 2.2-.82.44 1.1.16 1.92.08 2.12.51.56.82 1.27.82 2.15 0 3.07-1.87 3.75-3.65 3.95.29.25.54.73.54 1.48 0 1.07-.01 1.93-.01 2.2 0 .21.15.46.55.38A8.013 8.013 0 0016 8c0-4.42-3.58-8-8-8z"
                ></path>
              </svg>
            </a>
            <a href="https://iuuu.de/" className="ms-2" title="Besuchen Sie unsere Partner für digitale Lösungen und IT-Dienstleistungen.">
              <img src={i3u} width={32} height={32} style={{width: "32px", height: "32px", marginTop:"8px"}} alt="Unser Partner für digitale Lösungen"/>
            </a>
            {/*<a href="https://www.upwork.com/freelancers/~018fdca965d7f65716" className="ms-3 d-inline-block">
              <svg
                className="v-align-middle"
                style={{ height: "32px" }}
                enable-background="new 0 0 2500 2500"
                viewBox="0 0 2500 2500"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="m2315.4 0h-2130.7c-102 0-184.7 80.2-184.7 179.1v2141.7c0 99 82.7 179.2 184.7 179.2h2130.7c102 0 184.6-80.3 184.6-179.2v-2141.7c0-98.9-82.6-179.1-184.6-179.1z"
                  fill="#fff"
                />
                <path
                  d="m1834.6 1453.7c-98.4 0-190.5-41.7-274.3-109.6l20.4-95.8.9-3.5c18.2-102 75.8-273.3 253-273.3 132.9 0 241 108.3 241 241.3-.4 132.6-108.5 240.9-241 240.9zm0-726.7c-226.4 0-401.9 147.3-473.2 389.5-109-163.7-191.4-360.2-239.7-525.7h-243.6v634.8c0 125.1-101.9 227.1-226.9 227.1s-226.8-102-226.8-227.1v-634.8h-243.7v634.8c-.9 260 210.5 473.4 470.1 473.4s471-213.4 471-473.4v-106.5c47.4 98.9 105.4 198.7 175.9 287.5l-149.3 702.7h249.5l108.1-509.7c94.8 60.8 203.8 98.9 328.8 98.9 267.2 0 484.7-219.2 484.7-486.7-.2-267-217.7-484.8-484.9-484.8z"
                  fill="#000"
                />
              </svg>
            </a>*/}
          </div>

          <div className="footer-legal-links mx-4 text-end">
            <p className="mb-0">
              <NavLink to="/impressum" className={({ isActive }) => isActive ? "active" : ""} title="Impressum">Impressum</NavLink>
              <br />
              <NavLink to="/datenschutz" className={({ isActive }) => isActive ? "active" : ""} title="Datenschutz">Datenschutz</NavLink>
            </p>
          </div>
        </div>
      </footer>
)

const NavLinks = ({ withFooterLinks, reset }) => (<>
  <NavLink className={({ isActive }) => isActive ? "nav-link active" : "nav-link"} to="/" onClick={reset} title="Gemeinsam in die digitale Zukunft: Startseite für potenzielle Partnerschaften in der Welt der IT-Dienstleistungen in Augsburg und Umgebung.">
    <span className="mb-0">Home</span>
  </NavLink>
  <NavLink className={({ isActive }) => isActive ? "nav-link active" : "nav-link"} to="/profil" onClick={reset} title="Entdecken Sie unsere umfassenden IT-Dienstleistungen - Ihr maßgeschneidertes Profil für erstklassige Lösungen.">
    <span className="mb-0">Profil</span>
  </NavLink>
  <NavLink className={({ isActive }) => isActive ? "nav-link active" : "nav-link"} to="/kontakt" onClick={reset} title="Kontaktieren Sie uns für erstklassigen IT-Service. Wir sind hier, um Ihre Fragen zu beantworten, Lösungen anzubieten und eine partnerschaftliche Zusammenarbeit zu beginnen.">
    <span className="mb-0">Kontakt</span>
  </NavLink>
</>)

const Layout = (props) => {
  const [open, setOpen] = useState(false);

  const reset = () => {
    setOpen(false)
  }

  useMatomoTracking();

  const { children } = props;
  return (
    <>
      <header className="container py-0 pb-3 bg-white">
        <div className="d-flex justify-content-between align-items-center">
          <div className="title-box pb-0">
            <Link to="/"  title="Gemeinsam in die digitale Zukunft: Startseite für potenzielle Partnerschaften in der Welt der IT-Dienstleistungen in Augsburg und Umgebung.">
            <img alt="IT-Dienstleistungen-Hauser" src={logo} style={{height: 55, marginTop: 18.5}} title="IT-Dienstleistungen-Hauser"/>
            </Link>
          </div>

          <nav className="d-flex d-lg-none" >
            {!open && <img src={bars} title="Mobiles Menü öffnen" alt="Mobiles Menü öffnen" style={{width: 40, height: 40, cursor: "pointer", marginTop: "18px", marginRight: "3vw"}} onClick={e => {
              e.preventDefault();
              setOpen(true)
            }}/>}
            {open && <img src={times} title="Mobiles Menü schließen" alt="Mobiles Menü schließen" style={{width: 25, height: 25, cursor: "pointer", marginTop: "18px", marginRight: "3.5vw"}} onClick={e => {
              e.preventDefault();
              setOpen(false)
            }}/>}

            {open &&
              <div className="mobile-nav-container">
                <NavLinks withFooterLinks reset={reset}/>
              </div>
            }
            
          </nav>

          <nav className="d-none d-lg-flex nav content text-uppercase justify-content-between pt-3">
            <NavLinks />
          </nav>
        </div>
      </header>
      <div className="container sep-box" />
      {children}
      <Footer />
    </>
  );
};

// const NotFound = () => (
//   <div className="container py-5">
//     <div className="d-flex justify-content-center mt-5">
//       <div className="content">
//         <h3>404</h3>
//         <p>Die Seite konnte nicht gefunden werden</p>
//       </div>
//     </div>
//   </div>
// );

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

const AppRoutes = () => {
  // const { pathname } = useLocation();
  // const navigate = useNavigate();

  // React.useEffect(() => {
  //   if (pathname.endsWith("/")) {
  //     navigate(pathname.slice(0, -1));
  //   }
  // });
  return (
    <Routes>
      <Route exact path="/" element={<Home />} />
      <Route exact path="/profil" element={<Leistungen />} />
      <Route exact path="/kontakt" element={<Contact />} />
      <Route exact path="/impressum" element={<Impressum />} />
      <Route exact path="/datenschutz" element={<Datenschutz />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}

const App = () => {
  return (
    <Router>
      <ScrollToTop />
      <Layout>
        <AppRoutes />
      </Layout>
    </Router>
  );
};

export default App;
