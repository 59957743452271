// import contact from "../img/contact.jpg";
// import contact from "../img/bg21.jpeg";
// import contact from "../img/bg4.jpeg";
// import contact from "../img/bg31.webp";

import { useState } from "react";

import contact from "../img/bg35.jpeg";

const validateEmail = (email) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

const Contact = () => {
  const [data, setData] = useState({});  

  document.title = "Kontakt | IT-Dienstleistungen Hauser";

  const emailValid = validateEmail(data.email);
  const canSend = emailValid && data.legalChecked && data.message && data.message.trim().length > 3;

  return (
    <>
      <div className="container px-0">
        <div className="cover-img-container" style={{background: `url(${contact})`}}>
          <h1 className="mb-0 cover-img-title">Kontakt</h1>
          <div className="w-100 cover-img" />
        </div>
      </div>
      <div className="container sep-box" />
      <div>
        <div className="d-flex justify-content-center text-start content-card contact first">
          {window.location.hash === "#erfolg" && <div className="text-start">Ihre Nachricht wurde erfolgreich versand!</div>}
          {window.location.hash === "#fehler" && <div className="text-start">Ups! Es gab leider einen Fehler beim versenden Ihrer Nachricht, bitte versuchen Sie es zu einem anderen Zeitpunkt erneut.</div>}

          {window.location.hash !== "#fehler" && window.location.hash !== "#erfolg" && 
          <form className="contact-form" action="/kontakt" method="POST">
            <div className="mb-3">
              <p>
                Auf der Suche nach maßgeschneiderten Lösungen für Softwareentwicklung, Webdesign oder den Einsatz von KI-Technologien? Füllen Sie unser Kontaktformular aus, um umgehend ein unverbindliches Erstgespräch zu vereinbaren. Gerne Remote oder auch vor Ort in Augsburg und Umgebung.
              </p>
            </div>
            <hr />
            <div className="mb-3">
              <label htmlFor="email-input" className="form-label">
                Ihre E-Mail Addresse
              </label>
              <input name="email" type="email" className={(emailValid || !data.email) ? "form-control" : "form-control is-invalid"} id="email-input" aria-describedby="emailHelp" 
              onChange={e => {
                setData({
                  ...data,
                  email: e.target.value
                });
              }}
              />
              <div id="emailHelp" className="form-text">
                Wir benötigen Ihre E-Mail um Sie nach einer Anfrage kontaktieren zu können. <br />
                <small>Ihre E-Mail Adresse wird mit niemandem geteilt.</small>
              </div>
            </div>
            <div className="mb-3">
              <label htmlFor="message-input" className="form-label">
                Ihre Nachricht
              </label>
              <textarea name="message" className="form-control" id="message-input" rows="7" 
                value={data.message}
                onChange={e => {
                setData({
                  ...data,
                  message: e.target.value
                });
              }}/>
            </div>
            <div className="mb-3">
              <label className="form-label">Sicherheitsabfrage: Was ist die Hauptstadt Deutschlands?</label>
              <input name="sanity" type="text" className="form-control" id="sanity" 
                value={data.sanityQuestion}
                onChange={e => {
                  setData({
                    ...data,
                    sanityQuestion: e.target.value
                  });
                }}
              />
            </div>
            <div className="d-flex justify-content-between">
              <div className="mb-3 form-check d-inline-block">
                <input name="legal" type="checkbox" className="form-check-input" id="contact-legal-check" value={data.legalChecked} onChange={e => {
                  setData({
                    ...data,
                    legalChecked: e.target.checked
                  });
                }}/>
                <label className="form-check-label text-start" htmlFor="contact-legal-check" style={{cursor: "pointer"}}>
                  <small>Ich bin mit Speicherung und Versand dieser Nachricht gemäß der Datenschutzerklärung einverstanden.</small>
                </label>
              </div>
            </div>
            {canSend ?
              (<button type="submit" className="btn btn-dark" title="Schicken Sie uns jetzt eine Anfrage.">
                Abschicken
              </button>) :
              (<button className="btn btn-dark" disabled={true} style={{background: "rgba(0,0,0,0.65)"}}>
                Abschicken
              </button>)
            }
          </form>
        }
        </div>
      </div>
    </>
  );
};

export default Contact;
