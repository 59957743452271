import leistungen from "../img/bg39.jpeg";

import datenanalysen from "../img/leistungen/datenanalysen.png"
import se from "../img/leistungen/software-entwicklung.png"
import hosting from "../img/leistungen/hosting.png"
// import seo from "../img/leistungen/seo.png"
import webdesign from "../img/leistungen/webdesign.png"

const Profil = () => {

  document.title = "Profil | IT-Dienstleistungen Hauser";

  return (
    <>
      <div className="container px-0">
        <div className="cover-img-container" style={{background: `url(${leistungen})`}}>
          <span className="mb-0 cover-img-title slogan">Profil</span>
          <div className="w-100 cover-img" style={{ objectPosition: "center" }} />
        </div>
      </div>
      <div>
        <div className="d-flex justify-content-center content-card first" style={{ marginLeft: 40 }}>
          <div className="content ">
            <h1 className="in-box highlight-text">DAS LEISTUNGSSPEKTRUM</h1>
             <div style={{ paddingTop: 30 }}>
                <p>
                  Vertrauen und direkte Kommunikation ist das Fundament jeder erfolgreichen Zusammenarbeit und spielt die Zentrale Rolle bei der Gestaltung von langfristigen Partnerschaften und dem Erreichen gemeinsamer Ziele.  



                  Die nachfolgenden Dienstleistungen sind ein Ausblick unseres Angebotsspektrums.

                  Lassen Sie uns gemeinsam ein Erfolgsprojekt erschaffen. In Augsburg und Umgebung oder durch flexible Remote-Zusammenarbeit.
                {/*Erfolgsgeschichten zeigen, dass Vertrauen die Grundlage für innovative Ideen, effektive Kommunikation und das Überwinden von Herausforderungen ist. 
                Vertrauen schafft ein positives Arbeitsumfeld, in dem sich Teammitglieder gegenseitig unterstützen und ihr volles Potenzial entfalten können. Nutzen Sie die Kraft des Vertrauens,
                 um Ihre Zusammenarbeit auf ein neues Level zu bringen und Großes zu erreichen.


                  Erstellung von Webseiten in ansprechendem und kreativen Design: Brachenspezifisch und Ihren Vorstellungen entsprechend.*/}
                </p>
                {/*<p className="mt-2">
                  Webdesign mit Fokus auf mobile Endgeräte (responsives Design) ist dabei selbstversändlich. Auf jedem Endgerät soll Ihre Website ansprechend aussehen.
                </p>
                <p className="mt-2">
                  Nichts ist wichtiger als das die Auffindbarkeit Iherer Webpräsenz über alle gängigen Suchmaschinen.
                  Professionelle Suchmaschinenoptimierung durch die Verwendung verschiedener Analysetools ist bei der Erstellung Ihrer
                  Webpräsenz ein beständiges Ziel.
                </p>*/}
              </div>
          </div>
        </div>
      </div>
      <div>
        <div className="d-flex justify-content-center content-card" style={{ marginLeft: 75 }}>
          <div className="content ">
            <h2>Webdesign & SEO</h2>

            <div className="d-block d-md-flex">
              <div >
                <p>
                  Erstellung von Webseiten in ansprechendem und kreativen Design: Brachenspezifisch und Ihren Vorstellungen entsprechend.
                </p>
                <p className="mt-2">
                  Webdesign mit Fokus auf mobile Endgeräte (responsives Design) ist dabei selbstversändlich. Auf jedem Endgerät soll Ihre Website ansprechend aussehen.
                </p>
                <p className="mt-2">
                  Nichts ist wichtiger als das die Auffindbarkeit Iherer Webpräsenz über alle gängigen Suchmaschinen.
                  Professionelle und organische Suchmaschinenoptimierung durch die Verwendung verschiedener Analysetools ist bei der Erstellung Ihrer
                  Webpräsenz ein beständiges Ziel.
                </p>
              </div>
              <img src={webdesign} title="Webdesign Lösungen und individuelle Möglichkeiten ihre Projekte nach Ihren Vorstellungen zu gestalten" alt="Individuelle Webdesign Lösungen, Erstellung eigener Front-End Lösungen" className="mx-auto mw-100 portfolio-img ps-md-4" style={{ width: "560px" }} />
            </div>
          </div>
        </div>
      </div>
      <div>
        <div className="d-flex justify-content-center content-card" style={{ marginLeft: 55 }}>
          <div className="content">
            <h2>Hosting</h2>

            <div className="d-block d-md-flex">
              <img src={hosting} title="Monitoring Lösungen um Ihre Hosting-Umfeld sicher und hochverfügbar zu gestalten." alt="Hosting Monitoring Panels, Übersicht ihrer Hosting Infrastruktur, Analyse Hosting-Performance." className="mx-auto mw-100 portfolio-img pe-md-4" style={{ width: "390px" }} />
              <div >
                <p>
                  Wir übenehmen die Einrichtung Ihrers Hosting-Paketes und die Migration/Registrierung Ihrer Domains. Auch
                  Performance-kritische Anforderungen stellen kein Problem dar: Das Optimieren Ihrer Website Performance, sowie Strukturierung
                  Ihrer Infrastruktur sind Teil unseres Profils, dies geht Hand in Hand mit Ihren Webdesign Anforderungen.
                </p>
                <p className="mt-2">
                  Ob Optimierungen im Front-End, die Verteilung der Servernutzlast auf
                  verscheidene Serverinstanzen mit Load-Balancer oder optimiertes Caching-Verhalten von medienintensiven Inahlten Ihrer
                  Landingpage. 
                </p>
                <p className="mt-2">
                Darüber hinaus bieten wir auch regelmäßige Überwachung und Wartung Ihrer Hosting-Umgebung an, damit Ihre Website stets reibungslos läuft und die höchstmögliche Verfügbarkeit gewährleistet ist.
                </p>
                <p className="mt-2">Ob Unterstützung bei der Implementierung maßgeschneiderter Cloud-Lösungen oder Verwendung klassischer Hosting-Anbieter: Profitieren Sie von effizienten Lösungen zur Cloud-Migration und -Management für eine nahtlose Integration Ihrer Software-Anforderungen.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div className="d-flex justify-content-center content-card" style={{ marginLeft: 120 }}>
          <div className="content">
            <h2>Softwareentwicklung</h2>

            <div className="d-block d-md-flex">
              <div >
                <p>
                  Sind Sie auf der Suche nach individuellen und innovativen Softwareentwicklung für Ihr Unternehmen? Wir entwickeln Software nach Ihren
                  Anforderungen und fokusieren uns auf die Automatisierung Ihrer Prozesse. Entwicklung von Software als alleinstehende
                  Komplettlösung, oder als Plugin/Addon in ihrer existierenden Infrastruktur.
                </p>
                <p className="mt-2">
                  Innsbesondere die Entwicklung und Integration von Konfiguratoren oder interaktiven Webanwendugnen ist eines unserer wesentlichsten Angebote.
                  Erweitern und individualisieren Sie so beispielsweise Ihre Online-Bestellprozesse. 
                </p>
                <p className="mt-2">
                  Unsere Softwareentwicklung ermöglichen Ihnen eine nahtlose Integration in Ihre bestehende Infrastruktur, um einen reibungslosen Betrieb sicherzustellen.
                </p>
              </div>
              <img src={se} title="Softwareentwicklung im Bereich Datenhaltung. Beispieldarstellung einer möglichen Softwareentwicklung-Lösung, individuell gestaltet und in Ihre Prozesse integriert." alt="Softwareentwicklung einer Custom Lösung, Softwareentwicklung für Webhosting-Anwendungen" className="mx-auto mw-100 portfolio-img ps-md-4" style={{ width: "560px" }} />
            </div>
          </div>
        </div>
      </div>
      <div>
        <div className="d-flex justify-content-center content-card" style={{ marginLeft: 75 }}>
          <div className="content">
            <h2>Datenanalysen & Machschinelles Lernen & KI-Lösungen</h2>

            <div className="d-block d-md-flex">
              <img src={datenanalysen} title="Lassen Sie sich Plots verscheidener Datenanalysen erstellen. Nutzen Sie KI-Architekturen mit denen Sie neue Infromationen aus Ihren Daten gewinnen können" alt="Datenanalysen verschiedene Plots, Ergebnissvisualisierung von KI Erkenntnissen und Regressionsanlysen" className="mx-auto mw-100 portfolio-img pe-md-4" style={{ width: "530px" }} />
              <div >
                <p>
                  Automatisierung von statistischen Auswertungen belibiger Betriebs- und Prozessdaten um den Wissensgewinn in Ihrem
                  Unternehmen zu maximieren.
                </p>
                <p className="mt-2">
                  Analyse mit klassischen statistischen Tools wie Regressionsanlysen oder Integration komplexer KI-Technologien (künstliche Intelligenz) zur 
                  Bewältigung von Klassifkationsproblemen und zur automatisierten Text-/Bilderstellung mit KI-Tools wie ChatGPT oder freien Sprachmodell (LLMs) Alternativen. 
                  Erklärbare KI durch die Aufbereitung der Einflussnahme von Modellparamtern.
                </p>
                <p className="mt-2">
                  Anschauliche Ergebnissvisualisierung durch Plots und Animationen um komplexe Sachverhalte für Sie auf das wesentliche zu reduzieren.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div className="d-flex justify-content-center content-card" style={{ marginLeft: 100, marginBottom: 100 }}>
          <div className="content">
            <h2>Datenschutz Optimierungen</h2>
            <div className="d-block d-md-flex">
              
              <div >
                <p>
                  Anpassungen Ihrer Webpräsenz oder Software damit diese den Anforderungen der Datenschutz-Grundverordnung (DSGVO) gerecht werden.
                </p>
                <p className="mt-2">
                  Ersetzung von klassichen Analytics-Tools durch datensparsame Alternativen, die Integration von Google-Schriftarten lokal auf 
                  Ihrem eigenen Server oder das Ersetzen von risikobehafteten Abhängigkeiten durch eigene Softwarelösungen. Überprüfen Sie hier, ob Ihre Website den Anforderungen der Datenschutz-Grundverordnung entspricht:
                  {" "}<a href="https://dr-dsgvo.de/webseiten-check/" title="Prüfen Sie die DSGVO-Konformität Ihrer Website. Nutzen Sie diesen Datenschutz Check, um sicherzustellen, dass Sie die rechtlichen Anforderungen erfüllen. Schützen Sie Ihre Daten und Websitebesucher.">
                    https://dr-dsgvo.de/webseiten-check/ 
                  </a>.
                </p>
                <p className="mt-2">
                  Bei jeder Softwareentwicklung wird auf die sichere Verabeitung der personenbezogenen Daten Ihrer Kunden gemäß der europäischen Datenschutz-Grundverordnung geachtet.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Profil;
