import bg from "../img/bg34.jpeg";

const Impressum = () => {
  
  document.title = "Impressum | IT-Dienstleistungen Hauser";

  return (
    <>
      <div className="container px-0">
        <div className="cover-img-container" style={{background: `url(${bg})`}}>
          <h1 className="mb-0 cover-img-title">Impressum</h1>
          <div className="w-100 cover-img" style={{ objectPosition: "center" }} />
        </div>
      </div>
      <div>
        <div className="d-flex justify-content-center content-card first imprint" style={{ marginLeft: 40 }}>
          <div className="content">
            <div className="d-block d-md-flex text-start">
              <div style={{ paddingTop: 30 }}>

                <h3>Angaben gem&auml;&szlig; &sect; 5 TMG</h3>
                <p>Andreas Hauser<br />
                IT-Dienstleistungen<br />
                Leonhardstr. 82<br />
                86415 Mering</p>

                <h3>Kontakt</h3>
                <p>Telefon: +4915737043757<br />
                E-Mail: info@it-dienstleistungen-hauser.de</p>

                <h3>EU-Streitschlichtung</h3>
                <p>Die Europ&auml;ische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS) bereit: 
                <a href="https://ec.europa.eu/consumers/odr/" target="_blank" rel="noopener noreferrer">https://ec.europa.eu/consumers/odr/</a>.<br /> Unsere E-Mail-Adresse finden Sie oben im Impressum.</p>

                <h3>Verbraucher&shy;streit&shy;beilegung/Universal&shy;schlichtungs&shy;stelle</h3>
                <p>Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Impressum;
