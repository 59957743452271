import { Link } from "react-router-dom";
// import home from "../img/bg18.jpeg";
// import home from "../img/bg21.jpeg";
// import home from "../img/bg20.png";
import home from "../img/bg41.jpeg";

const Home = () => {
  document.title = "IT-Dienstleistungen Hauser";
  return (
  <>
    <div className="container px-0">
      <div className="cover-img-container" style={{background: `url(${home})`}}>
        <span className="mb-0 cover-img-title slogan" style={{ textAlign: "right" }}>
          Gemeinsam
          <br />
          Zukunft
          <br />
          Gestalten
        </span>
        <div className="w-100 cover-img"/>
      </div>
    </div>
    <>
      <div className="d-flex justify-content-center content-card home first" style={{ marginLeft: 40 }}>
        <div className="content">
          <h1 className="in-box highlight-text">IT DIENSTLEISTUNGEN AUGSBURG</h1>
          {/*<h2 className="mt-4">Das Spektrum</h2>*/}
          <p className="mt-4">
          Sie möchten eine Online-Präsenz erstellen, optimieren oder erweiteren? 
          Eine Individuelle Softwareentwicklung für Ihre Betriebsprozesse implementieren oder anpassen? 
          Statistische Auswertungen kleiner oder großer Datenmengen durchführen? 
          IT-Dienstleistungen Hauser ermöglicht Ihnen die Realisierung Ihrer IT-Projekte im betrieblichen oder privaten Rahmen. 
          {/*Im Zentrum stehen dabei die Optimierung Ihrer Betriebsprozesse. */}

          Egal ob Sie versuchen Ihre Kudenaquise zu verbessern, Ihre internen Porzesse zu automatisieren oder den Bestellprozess für Ihre Kunden zu erleichtern.

          </p>
          <hr />
          <div className="text-center">
            <strong>Über 5 Jahre Erfahrung im Bereich Softwareentwicklung</strong><br/>
            <strong>Webdesign, Entwicklung und IT-Dienstleistungen am Standort Augsburg und Umgebung</strong>
          </div>
        </div>
      </div>
      <div className="d-flex justify-content-center content-card home" style={{ marginLeft: 60 }}>
        <div className="content">
          <h2>Die Zusammenarbeit</h2>
          <p>
            Das primäre Ziel bei der Zusammenarbeit ist es Ihnen dabei zu helfen, Ihre individuellen Ziele und Wünsche durch den Einsatz von
            Technologien zu ermöglichen. Im Fokus stehen dabei die enge Zusammenarbeit, ein stets kurzer Kontaktweg und Zeit für Ihr Projekt. Schritt für Schritt soll einem für Sie 
            vollständig zufriedenstellendem Endergebnis entgegen gearbeitet werden.
          </p>
        </div>
      </div> 
      <div className="d-flex justify-content-center content-card home" style={{ marginLeft: 40, marginBottom: 100 }}>
        <div className="content">
          <h2>Das Ziel</h2>
          <p>
            Das Ziel der Zusammenarbeit kann sich vielfältig darstellen:
            Am Ende soll Ihr Vorhaben effizient und resilient gestaltet sein. 

            Wesentlich soll neben der schlichten Erfüllung Ihrer Anforderungen auch eine zukunftssichere und innovative Ausgestaltung sein: 
            Minimaler Betreuungsaufwand und das Formen eines Fundaments für Ihre betriebliche Weiterentwicklung. Mithilfe individueller Softwareentwicklung, Webdesign oder durch die Erstellung und Integration von Analyse-Ergebnisse durch KI-Technologien oder klassiche Statistik.
          </p>
          <div className="d-flex justify-content-center">
            <span className="d-block text-center mt-4">
              <Link to="/kontakt" className="btn btn-dark" title="Kontaktieren Sie uns für erstklassigen IT-Service. Wir sind hier, um Ihre Fragen zu beantworten, Lösungen anzubieten und eine partnerschaftliche Zusammenarbeit zu beginnen."><strong>Kontakt</strong></Link>
            </span>
            <span className="d-block text-center mt-4 ms-2">
              <Link to="/profil" className="btn btn-link" title="Entdecken Sie unsere umfassenden IT-Dienstleistungen - Ihr maßgeschneidertes Profil für erstklassige Lösungen."><strong>Weitere Infos <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-right" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/>
</svg></strong></Link>
            </span>
          </div>
        </div>
      </div>
    </>
  </>
)
};

export default Home;
